import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useEffect, useState } from "react";

type ConfirmationDialogType = {
    openDefault : boolean,
    onClose : any
}

export const ConfirmationDialog = ({openDefault = false, onClose} : ConfirmationDialogType) => {
    const [open, setOpen] = useState<boolean>(openDefault);
    
    useEffect(()=>{
        setOpen(openDefault);
    },[openDefault])

    const yesHandler = ()=>{
        onClose(true)
        setOpen(false);
    };

    const noHandler = () => {
        onClose(false);
        setOpen(false);
    }
    
    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle > {"Confirmation"} </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Est vous sûr de vouloir reinitialiser le formulaire ?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={noHandler}>Non</Button>
            <Button onClick={yesHandler} autoFocus>Oui</Button>
        </DialogActions>
    </Dialog>
}