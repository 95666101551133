import React from 'react';
import { Chooser } from './Chooser';

type TypeChooserProps = {
    onChange: Function
}

const options = [
    { label: 'Événement', id: 'event' },
    { label: 'Information', id: 'info' },
    { label: 'Articles', id: 'article' },
];

export const TypeChooser = ({ onChange }: TypeChooserProps) => {
    return <Chooser
        onChange={onChange}
        options={options}
        label="Quoi créer ?"
        needsConfirmation={true}  
    />
}