import { Button, Grid, TextField, Typography } from "@mui/material";
import { EditorCMS } from "../cms/EditorCMS";
import { useMemo, useState } from "react";
import { Box } from "@mui/system";
import { useDataProvider } from "../../context/DataProvider";
import { FormProps } from "./_types";
import { InfoItem } from "../infoItem/InfoItem";


export const InfoForm = ({activity} : FormProps) => {

    const [title, setTitle] = useState<string>("");
    const [date, setDate] = useState<string>(new Date().toLocaleString("fr-FR").split(' ')[0]);
    const [content, setContent] = useState<string>("");

    const {addInfo} = useDataProvider();

    const createInfoHandler = () => {
        setTitle('');
        setContent("");
        addInfo({
            activity : activity ?? "",
            title : title,
            date : date,
            content : content,
        });
    };

    const onTitleChange = (event : any) =>{
        setTitle(event.target.value ? event.target.value : "");
    };

    const onContentChange = (value : string) => {
        setContent(value);
    };

    const activityString = useMemo(()=>{
        let res : string = title? title : "";
        if(activity !== "global")
            res = '['+activity?.substring(0,1).toUpperCase()+activity?.substring(1,activity.length)+'] '+res;
        return res;
    },[activity,title]);

    if(activity == null || activity == "")
        return <></>; 

    return <>
        <Typography style={{margin : "1rem"}} variant="h5" component="h5">Créer événement</Typography>
        <Grid container spacing={3} direction="column">
            <Grid item>
                <TextField fullWidth onChange={onTitleChange} value={title} label="Titre" variant="outlined" />
            </Grid>
        </Grid>
        <EditorCMS onChange={onContentChange} />
        <Typography style={{margin : "1rem"}} variant="h5" component="h5">Rendu en ligne</Typography>
        <InfoItem title={activityString} date={date} content={content}/>
        <Button variant="contained" onClick={createInfoHandler}>Valider</Button>
        <Box sx={{marginBottom  : '4rem'}}></Box>
    </>;
};