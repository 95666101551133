import { Paper } from '@mui/material';
import React,{ useState } from 'react';
import { EditorState } from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css';
import { stateToHTML } from "draft-js-export-html";
import { useEffect } from 'react';

export const EditorCMS = ({onChange} : {onChange : any})=>{
    const [editorState, setEditorState] = useState(
		() => EditorState.createEmpty(),
	);

    useEffect(()=>{
        const doc = stateToHTML(editorState.getCurrentContent());
        onChange(doc);
    },[editorState]);


    return <Paper className='wrapper-class'>
        <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbar={{
                options: [
                    'inline', 
                    'blockType', 
                    'fontSize', 
                    'fontFamily', 
                    'list', 
                    'textAlign', 
                    'link'
                ],
                inline : {
                    options : ['bold', 'italic', 'underline']
                }
            }}
        />
    </Paper>
};
