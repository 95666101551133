import { Button, Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EditorCMS } from "../cms/EditorCMS";
import { EventItem } from "../eventItem/EvenItem";
import { useMemo, useState } from "react";
import { Box } from "@mui/system";
import { useDataProvider } from "../../context/DataProvider";
import { FormProps } from "./_types";
import dayjs, { Dayjs } from "dayjs";


export const EventForm = ({activity} : FormProps) => {

    const [title, setTitle] = useState<string>("");
    const [date, setDate] = useState<Dayjs>(dayjs(Date.now()));
    const [hour, setHour] = useState<string>("");
    const [minutes, setMinutes] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [shortDescription, setShortDescription] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const {addEvent} = useDataProvider();

    const onTitleChange = (event : any) =>{
        setTitle(event.target.value ? event.target.value : "");
    };
    const onDateChange = (date : any) => {
        setDate(date);
    };
    const onHourChange = (event : any) => {
        setHour(event.target.value ? event.target.value : "");
    };
    const onMinuteChange = (event : any) => {
        setMinutes(event.target.value ? event.target.value : "");
    };
    const onLocationChange = (event : any) => {
        setLocation(event.target.value ? event.target.value : "");
    };
    const onShortDescriptionChange = (event : any) => {
        setShortDescription(event.target.value ? event.target.value : "");
    };
    const onDescriptionChange = (value : string) => {
        setDescription(value);
    };

    const hourString = useMemo(()=>{
        let res : string = dayjs(date).format("DD/MM/YYYY");
        if(hour && hour !== "")
            res += " à " + hour+"h";
        if(minutes && minutes !== "")
            res += +minutes;
        return res;
    },[date,hour, minutes]);

    const activityString = useMemo(()=>{
        let res : string = title? title : "";
        if(activity !== "global")
            res = '['+activity?.substring(0,1).toUpperCase()+activity?.substring(1,activity.length)+']'+res;
        return res;
    },[activity,title]);


    const createEventHandler = () => {
        setTitle('');
        setHour("");
        setMinutes("");
        setLocation("");
        setShortDescription("");
        setDescription("")
        addEvent({
            activity : activity ?? "",
            title : title,
            date : hourString,
            location : location,
            shortDescription : shortDescription,
            description : description
        })
    };

    if(activity == null || activity == "")
        return <></>; 

    return <>
        <Typography style={{margin : "1rem"}} variant="h5" component="h5">Créer événement</Typography>
        <Grid container spacing={3} direction="column">
            <Grid item>
                <TextField fullWidth onChange={onTitleChange} value={title} label="Titre" variant="outlined" />
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={3}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Date desktop"
                                inputFormat="DD/MM/YYYY"
                                onChange={onDateChange}
                                value={date}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>      
                    </Grid>
                    <Grid item>
                        <Typography style={{margin : "1rem"}} component="p">à</Typography>
                    </Grid>
                    <Grid item>
                        <TextField onChange={onHourChange} value={hour} label="Heure"variant="outlined" />  
                    </Grid> 
                    <Grid item>
                        <Typography style={{margin : "1rem"}} component="p">h</Typography>
                    </Grid>
                    <Grid item>
                        <TextField onChange={onMinuteChange} value={minutes} label="Minutes" variant="outlined" />  
                    </Grid> 
                </Grid>
            </Grid>
            <Grid item>
                <TextField fullWidth onChange={onLocationChange}  value={location} label="Lieu de rendez vous" variant="outlined" />
            </Grid>
            <Grid item>
                <TextField fullWidth onChange={onShortDescriptionChange} value={shortDescription} label="Petite description" variant="outlined" />
            </Grid>
        </Grid>
        <EditorCMS onChange={onDescriptionChange} />
        <Typography style={{margin : "1rem"}} variant="h5" component="h5">Rendu en ligne (petit)</Typography>
        <EventItem title={activityString} date={hourString} location={location} shortDescription={shortDescription} description={null}/>
        <Typography style={{margin : "1rem"}} variant="h5" component="h5">Rendu en ligne (grand)</Typography>
        <EventItem title={activityString} date={hourString} location={location} shortDescription={null} description={description}/>
        <Button variant="contained" onClick={createEventHandler}>Valider</Button>
        <Box sx={{marginBottom  : '4rem'}}></Box>
    </>;
};