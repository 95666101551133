import React, { createContext, useContext, useEffect, useState } from "react";
import { useRequester } from "../utils/useRequester";

export type Event = {
    title : string,
    id? : number,
    date : string,
    activity? : string,
    description : string | null,
    shortDescription : string | null,
    location : string
}
export type Info = {
    id? : string,
    title : string,
    activity? : string,
    date : string,
    content : string
}

export type Article = {
    id? : string,
    title : string,
    activity? : string,
    date : string,
    content : string,
    pictures :  Array<string>
}

type DataType = {
    events : Array<Event>,
    infos : Array<Info>,
    articles : Array<Article>
}

type DataProviderType = {
    data : DataType,
    setData : Function,
    fetch : Function
}

const defaultValue : DataType = {
    events : [],
    infos : [],
    articles : []
};



const DataContext = createContext<DataProviderType>({ data : defaultValue, setData : ()=>{}, fetch : () => {}});

const DataProvider = ({children } : any) => {

    const {doRequest} = useRequester();
    const [data, setData] = useState<DataType>(defaultValue);

    const fetch = () => {
        doRequest("GET",(value :any)=>{
            setData(value);
        },"");
    }

    useEffect(()=>{
        fetch();
    },[]);      

    return (
        <DataContext.Provider value={{ data, setData, fetch }}>
            {children}
        </DataContext.Provider>
    );      
};

export const useDataProvider = () => {
    const { data, setData, fetch } = useContext<DataProviderType>(DataContext);
    const {doRequest} = useRequester();

    return {
        data,
        setData,
        addEvent : (event : Event)=>{
            doRequest("POST",(value :any)=>{
                console.log(value);
                fetch()
            },"/event", JSON.stringify(event));
        },
        addInfo : (info : Info) => {
            doRequest("POST",(value :any)=>{
                console.log(value);
                fetch()
            },"/info", JSON.stringify(info));
        },
        addArticle : (article : Article) => {

        }
    }
}

export default DataProvider;