import React from 'react';
import './eventItem.css';
import './index.css';
import calendar from './calendar.png';
import placeholder from './placeholder.png';
import parse  from 'html-react-parser';
import { Event } from '../../context/DataProvider';


export const EventItem = ({title, date, location, shortDescription, description} :Event) =>{
    return <div style={{backgroundColor : 'rgb(235,235,235)', padding : '1rem', margin : "2rem"}} ><div className="event">
        <div className="event-title">
            <h4>{title}</h4>
            <div className="event-date">
                <p>{date}</p>
                <img alt="" height="24px" src={calendar} />
            </div>
        </div>
        <div className="event-location">
            <img alt="" height="24px" src={placeholder} />
            <p>{location}</p>
        </div>
        {description == null ?<p className="event-short">{shortDescription}</p> : null}
        {description == null ? <div className="event-footer">
            <a className="important-btn">Voir plus</a>
        </div> : null}
        {description !== null ? <div className="event-short">{parse(description ?? "<p></p>")}</div> : null}
    </div>
    </div>
};