import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ConfirmationDialog } from '../ConfirmationDialog';

export type TypeChooserProps = {
    onChange : Function,
    label : string,
    options : Array<OptionChooser>
    needsConfirmation : boolean
}

export type OptionChooser = {
    label : string
    id : string
}

export const Chooser = ({onChange,label,options, needsConfirmation = false} : TypeChooserProps)=>{
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [value, setValue] = useState<OptionChooser | null>(null);
    const [tempValue, setTempValue] = useState<OptionChooser | null>(null);

    const onChangeHandler = (event : any, newValue : OptionChooser | null)=>{
        if(needsConfirmation && value !== null)
        {
            setTempValue(newValue);
            setOpenDialog(true);
        }
        else
        {
            setValue(newValue);
            onChange(newValue?.id);
        }
            
    };
    const dialogOnClose = (confirmed : boolean)=>{
        if(confirmed){
            setValue(tempValue);
            onChange(tempValue?.id);
        }
        setOpenDialog(false);
    };

    return <>
        <Autocomplete
            value={value}
            style={{marginBottom: "1rem"}}
            disablePortal
            onChange={onChangeHandler}
            options={options}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
        <ConfirmationDialog openDefault={openDialog} onClose={dialogOnClose}/>
    </>
}