import React from 'react';
import './infoItem.css';
import './index.css';
import calendar from './calendar.png';
import { Info } from '../../context/DataProvider';
import parse  from 'html-react-parser';

export const InfoItem = ({ title, date, content }: Info) => {
    return <div style={{backgroundColor : 'rgb(235,235,235)', padding : '0.8rem', margin : "0.5rem"}} ><div className="info">
        <div className="info-title">
            <h4>{title}</h4>
            <div className="info-date">
                <p>{date}</p>
                <img height="24px" src={calendar} />
            </div>
        </div>
        <div>{parse(content)}</div>
    </div></div>;
};