export const useRequester = () => {
    return {
        doRequest: (method : string, onResponse : any, url : string, body? : string) => {
            fetch("https://wascalade.fr/data"+ url, {
                method : method,
                body : body, 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                return res.json();
            }).then(onResponse);
        }
    };
};
