import React,{ useEffect, useState } from "react";
import { Event,useDataProvider } from "../../context/DataProvider";
import { EventItem } from "../eventItem/EvenItem";
import { ListProps } from "./_type";

export const EventList = ({activity} : ListProps) => {
    const [events, setEvents] = useState<Array<Event>>([]);
    const {data} = useDataProvider();
    
    //getting all the datas
    useEffect(()=>{
        setEvents(data?.events);
    },[data])
    

    return <>
        {events.map((e : Event, index)=>{
            const activityString = (()=>{
                let res : string = e.title? e.title : "";
                if(e.activity !== "global")
                    res = '['+e.activity?.substring(0,1).toUpperCase()+e.activity?.substring(1,e.activity.length)+'] '+res;
                return res;
            })();

            return <EventItem key={"event"+index} location={e.location} title={activityString} date={e.date} description={e.description} shortDescription={e.shortDescription} />
        })}
    </>;
};