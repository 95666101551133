import React,{ useEffect, useState } from "react";
import { Info, useDataProvider } from "../../context/DataProvider";
import { EventItem } from "../eventItem/EvenItem";
import { InfoItem } from "../infoItem/InfoItem";
import { ListProps } from "./_type";

export const InfoList = ({activity} : ListProps) => {
    const [infos, setInfos] = useState<Array<Info>>([]);
    const {data} = useDataProvider();

    //getting all the datas
    useEffect(()=>{
        setInfos(data?.infos);
    },[])
    

    return <>
        {infos.map((e : Info, index)=>{
            const activityString = (()=>{
                let res : string = e.title? e.title : "";
                if(e.activity !== "global")
                    res = '['+e.activity?.substring(0,1).toUpperCase()+e.activity?.substring(1,e.activity.length)+'] '+res;
                return res;
            })();

            return <InfoItem key={"event"+index} title={activityString} date={e.date} content={e.content} />
        })}
    </>;
};