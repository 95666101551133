import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ActivityChooser } from './components/controllers/ActivityChooser';
import { TypeChooser } from './components/controllers/TypeChooser';
import { ArticleForm } from './components/forms/ArticleForm';
import { EventForm } from './components/forms/EventForm';
import { InfoForm } from './components/forms/InfoForm';
import { EventList } from './components/list/EventList';
import { InfoList } from './components/list/InfoList';
import DataProvider from './context/DataProvider';

function App() {
	const [type, setType] = useState<string | null>(null);
	const [activity, setActivity] = useState<string | null>(null);

	const onChangeType = (id: string) => {
		setType(id);
	};

	const listCmp = (() => {
		if (type == null)
			return <></>;
		if (type == "event")
			return <>
				<Typography style={{ margin: "1rem" }} variant="h5" component="h5">Liste Evenement</Typography>
				<EventList activity={activity} />
			</>
		if (type == "info")
			return <>
				<Typography style={{ margin: "1rem" }} variant="h5" component="h5">Liste Infos</Typography>
				<InfoList activity={activity} />
			</>
	})();

	const form = useMemo(()=>{
		if(!type || type == "" || !activity || activity == "")
			return <></>;

		if(type == "event")
			return <EventForm activity={activity} /> ;
		if(type == "info")
			return <InfoForm activity={activity} /> ;
		if(type == "article")
			return <ArticleForm activity={activity}/> ;
	},[type,activity])

	return <DataProvider>
		<Typography style={{ margin: "1rem" }} variant="h4" component="h4">Wascalade Manageur</Typography>
		<Box style={{ margin: "1rem" }}>
			<TypeChooser onChange={onChangeType} />
			<ActivityChooser onChange={(id: string) => { setActivity(id) }} />
			<Grid container spacing={2}>
				<Grid item xs={6}>
					{form}
				</Grid>
				<Grid item xs={6}>
					{listCmp}
				</Grid>
			</Grid>
		</Box>
	</DataProvider>;
}

export default App;
