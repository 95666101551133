import React from 'react';
import { Chooser } from './Chooser';

type TypeChooserProps = {
    onChange : Function
}

export type OptionChooser = {
    label : string
    id : string
}

const options = [
    { label: 'Global', id: 'global' },
    { label: 'Escalade', id: 'escalade' },
    { label: 'Montagnisme - Alpinisme', id: 'montagnisme' },
    { label: 'Ecole', id: 'ecole' },
];

export const ActivityChooser = ({onChange} : TypeChooserProps)=>{
    return <Chooser
        onChange={onChange}
        options={options}
        label={"Quelle activité ?"}
        needsConfirmation={false}  
    />
}